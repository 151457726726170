// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD50-EdkhM-XUbUjZ06W4DLXEmuazlzxDM",
    authDomain: "shopping-list-a4f52.firebaseapp.com",
    databaseURL: "https://shopping-list-a4f52.firebaseio.com",
    projectId: "shopping-list-a4f52",
    storageBucket: "shopping-list-a4f52.appspot.com",
    messagingSenderId: "665972187529",
    appId: "1:665972187529:web:0d1c65958623d979b64158",
    measurementId: "G-WWMLWXTJDN"
  },
  domain: 'http://localhost:4200/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
