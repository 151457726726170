<div class="row">
  <app-banner-article
    [btitle]="'Suas listas disponíveis 24h em qualquer lugar'"
    [btext]="
      'Esqueça a listinha feita em papel que a gente sempre
      esquece em casa, e quando molha, e quando o vento leva,
      e quando.... Aqui você terá acesso sempre de qualquer 
      lugar do mundo e não terá que se preocupar em esquecer
      porque estará sempre na sua mão :)
      E tem mais, aqui você pode criar lista do mercado, farmácia, padaria,
      material escolar, etc. Não importa qual destinação, use a vontade :)'
    "
  ></app-banner-article>

  <div class="col s12 m8 l8">
    <app-loading [loading]="loading"></app-loading>
    <app-finish
      *ngIf="actionFinish"
      (cancelFinish)="cancelFinish()"
      [(totalShopping)]="totalPurchase"
      (finishData)="finishShopping($event)"
    ></app-finish>
    <app-confirmation
      [confirmation]="confirmation"
      [type]="confirmType"
      [msg]="confirmMsg"
      (btnAction)="redirectFinish()"
    ></app-confirmation>

    <div *ngIf="!actionFinish && !confirmation && !loading">
      <app-shared
        [sharedData]="shareData"
        [options]="shareOptions"
        [dataGenerate]="dataToExport"
        (hdDataGenerate)="dataGenerateToExport()"
      ></app-shared>

      <app-add-prod (newProduct)="addProduct($event)"></app-add-prod>

      <app-panel
        titlePanel="Produtos"
        showPanel="true"
        badge="{{ products.length }} produtos - Total {{totalPurchase | currency: 'BRL'}}"
        badgeColor="blue"
        badgeAnimate="true"
      >
        <div class="row" *ngIf="products.length < 1">
          <div class="col s12">Adicione produtos a sua compra :)</div>
        </div>
        <ul>
          <li
            class="padding-topBottom-4 no-padding-sides"
            *ngFor="let product of products; let i = index"
          >
            <app-prod-item 
              [product]="product"
              (delProduct)="delProduct(i)"
              (boughtProd)="boughtProd($event, i)"></app-prod-item>
          </li>
        </ul>
      </app-panel>
      <br />
      <div class="row">
        <div class="col s12 l6 center-align">
          <button
            type="button"
            class="btn-small btn-all green darken-4"
            (click)="saveList()"
            [disabled]="btnSave"
          >
            Salvar Lista <i class="material-icons right">save</i>
          </button>
        </div>
        <div class="hide-on-large-only">&nbsp;&nbsp;</div>
        <div class="col s12 l6 center-align">
          <button
            type="button"
            class="btn-small btn-all orange darken-4"
            (click)="finishShopping()"
            [disabled]="btnFinish"
          >
            Finalizar Compras <i class="material-icons right">done_all</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
