import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { AuthService } from 'src/app/auth/auth.service';

import { Error } from './error.class';
import { Toast } from 'src/app/lib/toast';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  passIcon: string = 'visibility';
  passType: string = 'password';
  loading: boolean = false;
  errorMsg: string = '';
  loginForm: UntypedFormGroup;
  newUser: boolean = false;
  param = {
    listId: '0',
    load: 'l',
    save: 'n',
  };

  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private error: Error,
    private libToast: Toast
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      name: [''],
    });

    this.activatedRouter.paramMap.subscribe((paramMap) => {
      if (paramMap.get('list')) this.param.listId = paramMap.get('list');
      if (paramMap.get('save')) this.param.save = paramMap.get('save');
      if (paramMap.get('load')) this.param.load = paramMap.get('load');
    });

    if (this.param !== null) {
      this.errorMsg =
        'Efetue login ou cadastre-se para ter acesso a este recurso.';
    }
  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }
  get name() {
    return this.loginForm.get('name');
  }

  showPassword(): void {
    this.passType = this.passType === 'text' ? 'password' : 'text';
    this.passIcon = this.passType === 'text' ? 'visibility_off' : 'visibility';
  }

  register(action: boolean) {
    if (this.newUser && action) {
      return this.login(true);
    }

    this.newUser = action ? true : false;
  }

  validForm() {
    if (this.email.value == '') {
      return 'Informe um endereço de email';
    }

    return null;
  }

  async login(register?: boolean) {
    try {
      this.loading = true;
      this.errorMsg = '';

      const valid = this.validForm();
      if (valid != null) {
        return (this.errorMsg = valid);
      }

      let res = null;
      if (register) {
        res = await this.authService.register(
          this.email.value,
          this.password.value
        );
        if (res && res.user) {
          await this.authService.updateUserProfile(this.name.value, null);
        }
      } else {
        res = await this.authService.login(
          this.email.value,
          this.password.value
        );
      }

      if (res == null || !res.user) {
        throw 'Falha no login';
      } else {
        return this.router.navigate([
          `list-products/${this.param.listId}`,
          { save: this.param.save, load: this.param.load },
        ]);
      }
    } catch (err) {
      this.errorMsg = this.error.handlerFireError(err);
      console.error(this.errorMsg);
    } finally {
      this.loading = false;
    }
  }

  async recoverPassword() {
    try {
      this.loading = true;
      this.errorMsg = '';

      const valid = this.validForm();
      if (valid != null) {
        return (this.errorMsg = valid);
      }

      await this.authService.sendPasswordResetEmail(this.email.value);

      this.libToast.showInfoToast(
        'Um e-mail será enviado para sua caixa de entrada, verifique inclusive nos spams, obriagdo!'
      );
    } catch (err) {
      this.errorMsg = this.error.handlerFireError(err);
      console.error(this.errorMsg);
    } finally {
      this.loading = false;
    }
  }
}
