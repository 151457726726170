<div id="modal_bought" class="modal" #modal_bought>
  <div class="modal-content">
    <h5 class="left-align text-bould">
      <i class="material-icons left">shopping_basket</i> {{ product.name }}
    </h5>
    <br />
    <div class="input-field">
      <input
        id="quantity"
        placeholder="Quantidade a ser comprada"
        type="text"
        class="validate"
        [(ngModel)]="product.quantity"
        (input)="hdInput($event, true, 8)"
        (change)="hdInput($event, true, 8)"
      />
      <label class="active" for="quantity">Quantidade a ser comprada</label>
      <span
        class="helper-text pink-text text-darken-4"
        *ngIf="alertInpt === 'quantity'"
      >
        {{ alertMsg }}
      </span>
    </div>
    <div class="input-field">
      <input
        #prodValue
        id="value"
        placeholder="Valor unitário R$"
        type="text"
        [(ngModel)]="product.value"
        (keyup.enter)="addCart('buy')"
        (input)="hdInput($event, true, 12)"
        (change)="hdInput($event, true, 12)"
      />
      <label class="active" for="value">Valor unitário R$</label>
      <span
        class="helper-text pink-text text-darken-4"
        *ngIf="alertInpt === 'value'"
      >
        {{ alertMsg }}
      </span>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col s12">
        <button
          type="button"
          class="waves-effect waves-red btn-small red darken-2"
          title="Cancelar compra do produto"
          (click)="addCart('cancel')"
        >
          <i class="material-icons right">delete_forever</i>Cancelar
        </button>
        <button
          type="button"
          class="waves-effect waves-green btn-small green darken1"
          title="Confirmar compra do produto"
          (click)="addCart('buy')"
        >
          <i class="material-icons right">done_all</i> Confirmar
        </button>
      </div>
    </div>
  </div>
</div>
<label class="secondary-content" title="Comprar Produto">
  <input
    type="checkbox"
    [name]="product.name"
    [value]="product.name"
    [checked]="bought"
    [(ngModel)]="bought"
    (click)="openModal($event)"
  />
  <span></span>
</label>
