import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner-article',
  templateUrl: './banner-article.component.html',
  styleUrls: ['./banner-article.component.scss']
})
export class BannerArticleComponent {

  @Input() btitle = "";
  @Input() btext = "";
  /** hide-in-med-and-down (default)  */
  @Input() showIn = "hide-on-small-only";
  
  constructor() { }

}
