<div class="forgot-page">
  <div class="row">
    <div class="col s12 center-align">
      <h5>Alterar Senha</h5>
    </div>
  </div>
	<app-alert [msg]="errorMsg" [show]="errorMsg"></app-alert>
  <app-loading [loading]="loading"></app-loading>
  <div class="row" *ngIf="!loading">
    <div class="col s12">
      <p>Por favor, informe uma nova senha e confirme</p>
    </div>
  </div>
  <form [formGroup]="frmSetNewPassword" (ngSubmit)="submit()" *ngIf="!loading">
    <div class="row white-back">
      <div class="col s12 input-field">
        <i class="material-icons prefix" (click)="showPassword('pass')">{{
          passObj.pass.icon
        }}</i>
        <input
          #loginPass
          id="login_pass"
          type="{{ passObj.pass.type }}"
          title="Senha"
          formControlName="password"
          maxlength="20"
          autocomplete="on"
        />
        <label for="login_pass" class="active">Senha</label>
        <span
          *ngIf="password.invalid && password.dirty && password.touched"
          class="helper-text red-text"
        >
          Senha inválida (minimo 6 caracteres)
        </span>
      </div>
    </div>
    <div class="row white-back">
      <div class="col s12 input-field">
        <i class="material-icons prefix" (click)="showPassword('confirm')">{{
          passObj.confirm.icon
        }}</i>
        <input
          id="login_pass_confirm"
          type="{{ passObj.confirm.type }}"
          title="Confirmação de senha"
          formControlName="confirmPassword"
          maxlength="20"
          autocomplete="on"
        />
        <label for="login_pass_confirm" class="active"
          >Confirmação de Senha</label
        >
        <span
          *ngIf="
            confirmPassword.invalid && confirmPassword.dirty && password.touched
          "
          class="helper-text red-text"
        >
          Senha inválida (minimo 6 caracteres)
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <button type="submit" class="btn orange darken-4" [disabled]="!frmSetNewPassword.valid">
          Alterar senha
        </button>
      </div>
    </div>
  </form>
</div>
