<div class="row">
  <div class="col s12">
    <h5><i class="material-icons left">warning</i> <b>IMPORTANTE</b></h5>
    <strong> Nenhum dado adicional será solicitado, nem na aplicação, muito menos por outro meio
      (email, sms, rede social, telefone etc). Não comercializamos ou compartilhamos informações.
    </strong>
  </div>
</div>
<div class="row">
  <div class="col s12">
    <h5>
      <i class="material-icons left">local_grocery_store</i><strong>Sobre</strong></h5>
    <p class="text-justify">
      Com a lista de compras você não precisa ficar lembrando o que ia comprar, nem se preocupar com molhar 
      amassar ou perder papel igual a lista que é feita em caderno, agenda etc.
      <br/>
      A lista de compras permanece 24 horas online, na palma da sua mão, mesmo que você perca ou esqueça
      seu celular, ela irá continuar no seu login e poderá ser acessada de qualquer
      outro aparelho conectado a internet.
      <br/>
      E o melhor, você poderá acompanhar suas compras mensais, aproveitar a lista de compras do mês anterior
      sem ter que recriar tudo novamente ou esquecer algum produto.
    </p>
    <a routerLink="/list-products/0" title="Criar minha lista">Criar minha lista</a>
    <p class="text-justify">
      <b>Importante: </b>
      Se você não quiser fazer seu cadastro, pode criar sua lista de compras normalmente, porém, os produtos
      adicionados serão excluidos quando você fechar a aplicação.
    </p>
    <a routerLink="/login" title="Criar cadastro">Quero salvar minha lista para as próximas compras</a>
  </div>
</div>
<div class="row">
  <div class="col s12">
    <h5><i class="material-icons left">security</i><strong>Privacidade</strong></h5>
    <p class="text-justify">
      Quando você fizer seu cadastro, serão solicitados somente um endereço de email, senha e nome.
      <strong>Nenhum dado adicional será solicitado, nem na aplicação, muito menos por outro meio
        (email, sms, rede social, telefone etc).
      </strong>
      Esses dados ficarão salvos e privados em servidores sob a tecnologia cloud computing da Google.
      <br/>
      <strong>
      Não compartilhamos informações, nem são usadas para fins comerciais ou qualquer outro que seja.
      </strong>
      <br/>
      A principal missão é colaborar com as pessoas que gostam de organizar suas compras e vida financeira.
    </p>
  </div>
</div>
<div class="row">
  <div class="col s12">
    <h5><strong><i class="material-icons left">sentiment_very_satisfied</i> Obrigado!</strong></h5>
    <p class="text-justify">
      Se você chegou até aqui, muito obrigado!
      <br/>
      A Lista de compras online irá te ajudar a poupar tempo e dinheiro, organizando melhor
      sua vida financeira com as compras do mês, semanais ou seja qual for.
      Esqueça a lista de papel que molha, rasga, perde e ainda precisa procurar uma caneta.
      <br/>
      Deixe sua avaliação e opinião para podermos continuar melhorando.
    </p>
    <a routerLink="/list-products/0" title="Criar minha lista">Criar minha lista</a>
    <!-- versionMajor.release.fix -->
    <p class="right-align">V2.1.0 - Beta</p>
  </div>
</div>