import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InputsValidation {

  limitVal(value, limit) {
    return value.length > limit ? value.slice(0, limit) : value;
  }

  numOnlyVal(value) {
    return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  }

}
