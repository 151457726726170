/**
 * Define the level of security of the link
 * 
 * all = the link will appear to all user
 * public = only when the user is not logged
 * private = only when the user is logged
 */
export enum Secure {
  all = 'all',
  public = 'public',
  private = 'private'
}

export class LinkModel {
  url: string;
  title: string;
  icon: string;
  secure: Secure;
}
