import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import * as M from 'materialize-css/dist/js/materialize';
import { InputsValidation } from 'src/app/lib/InputsValidation';
import { Product } from '../product';

enum Action {
  Cancel = 'cancel',
  Buy = 'buy'
}

@Component({
  selector: 'app-bought-prod',
  templateUrl: './bought-prod.component.html',
  styleUrls: ['./bought-prod.component.scss'],
})
export class BoughtProdComponent {
  @Input() product: Product;
  @Input() bought: boolean = false;
  @Output() boughtChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('modal_bought') modalBought: ElementRef;
  @ViewChild('prodValue') prodValue: ElementRef;
  instanceModal: any;

  alertInpt: string = '';
  alertMsg: string = '';

  constructor(private inputsValidation: InputsValidation) {}

  ngAfterViewInit() {
    this.instanceModal = M.Modal.init(this.modalBought.nativeElement, {
      startingTop: '1%',
      endingTop: '1%',
    });
  }

  openModal($event) {
    if (!this.bought) {
      this.instanceModal.open();
      this.prodValue.nativeElement.focus();
    } else {
      delete this.product.value;
      this.boughtChange.emit(false);
    }
  }

  hdInput($event: any, numonly: boolean, limit = 0) {
    const value = $event.target.value;
    let nval = this.inputsValidation.limitVal(value, limit);
    nval = numonly ? this.inputsValidation.numOnlyVal(nval) : nval;
    $event.target.value = nval;
  }

  addCart(action: string) {
    if (action == Action.Cancel) {
      // Using setTimeout because checkbox
      setTimeout(() => (this.bought = false));
      this.boughtChange.emit(false);
      return this.instanceModal.close();
    }

    if (this.product.quantity < 1) {
      this.alertInpt = 'quantity';
      return this.alertMsg = 'Informe a quantidade do produto';
    }

    if (!this.product.value || this.product.value == 0) {
      this.alertInpt = 'value';
      return this.alertMsg = 'Informe o valor unitário do produto';
    }

    this.alertInpt = '';
    this.alertMsg = '';

    setTimeout(() => (this.bought = true));
    this.boughtChange.emit(true);
    this.instanceModal.close();
  }
}
