<div class="row">
  <div class="col s12 center-align">
    <h5>Finalizando Compras</h5>
  </div>
</div>
<app-alert [msg]="alertMsg" [show]="alertMsg"></app-alert>
<div class="row">
  <div class="input-field col s12">
    <input id="emporium" autofocus type="text" title="Qual local você está comprando?" [(ngModel)]="emporium" maxlength="35">
    <label for="emporium">*Local da compra (nome do estabelecimento)</label>
  </div>
  <div class="input-field col s12">
    <input id="discount" type="number" title="Qual o valor do desconto?" [(ngModel)]="discount" min="0" (keyup)="preventInput($event)">
    <label for="discount">R$ Valor do Desconto</label>
  </div>
  <div class="input-field col s12">
    <select multiple ngModel #select_method (change)="setPaymentMethod()">
      <option *ngFor="let method of paymentMethodList" [value]="method.name">
        {{ method.name }}
      </option>
    </select>
    <label>*Forma de pagamento</label>
  </div>
</div>
<div class="row">
  <div class="col s8 text-bould">
    Valor da sua lista de compras: <br/>
    Desconto: <br/>
    Valor total:
  </div>
  <div class="col s4 right-align text-bould">
    {{ totalShopping |  currency: 'BRL' }} <br/>
    {{ discount | currency: 'BRL' }} <br/>
    {{ totalWithDiscount | currency: 'BRL' }}
  </div>
</div>
<div class="row">
  <div class="col s12 l6 center-align">
    <button type="button" class="btn-small btn-all orange darken-4" title="Finalizar Compras" (click)="finishList()">
      Finalizar Compras<i class="material-icons right">done_all</i>
    </button>
  </div>
  <div class="hide-on-large-only">
    &nbsp;&nbsp;
  </div>
  <div class="col s12 l6 center-align">
    <button type="button" class="btn-small btn-all red darken-2" title="Cancelar" (click)="cancel()">
      Cancelar Finalização<i class="material-icons right">cancel</i>
    </button>
  </div>
</div>