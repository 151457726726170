import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnChanges {

  @Input() titlePanel: string;
  @Input() badge: string = '';
  @Input() badgeColor: string = 'blue-grey darken-4';
  @Input() showPanel: boolean = false;
  @Input() badgeAnimate: boolean = false;

  animateClass: string = ''

  ngOnChanges() {
    if (!this.badgeAnimate) {
      return;
    }

    this.animateClass = 'animate__shakeX';
    setTimeout(() => { this.animateClass = ''; }, 500);
  }

  hdPanel(): void {
    this.showPanel = !this.showPanel;
  }

}
