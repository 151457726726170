import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { InputsValidation } from 'src/app/lib/InputsValidation';
import { Product } from '../product';

@Component({
  selector: 'app-add-prod',
  templateUrl: './add-prod.component.html',
  styleUrls: ['./add-prod.component.scss'],
})
export class AddProdComponent {
  @Output() newProduct = new EventEmitter<Product>();

  @ViewChild('prodNameInput') prodNomeInput: ElementRef;
  @ViewChild('prodQtdInput') prodQtdInput: ElementRef;

  prodName = '';
  prodNError = '';
  prodQtd = '';
  prodQError = '';

  constructor(private inputsVal: InputsValidation) {}

  private inputFocus(which: string) {
    if (which == 'name') {
      this.prodNomeInput.nativeElement.focus();
    } else {
      this.prodQtdInput.nativeElement.focus();
    }
  }

  ngAfterViewInit() {
    this.inputFocus('name');
  }

  hdInput($event, limit = 0, numonly = false) {
    const value = $event.target.value;
    let nval = this.inputsVal.limitVal(value, limit);
    nval = numonly ? this.inputsVal.numOnlyVal(value) : nval;
    $event.target.value = nval;
  }

  addProduct() {
    if (this.prodName.trim() === '') {
      this.inputFocus('name');
      return (this.prodNError = 'Insira o nome do produto');
    }

    if (this.prodQtd.trim() === '') {
      this.inputFocus('qtd');
      return (this.prodQError = 'Insira a quantidade');
    }

    this.newProduct.emit(new Product(this.prodName, Number(this.prodQtd)));
    this.prodName = '';
    this.prodQtd = '';
    this.prodNError = '';
    this.prodQError = '';
    this.inputFocus('name');
  }
}
