<div class="row">
  <div class="input-field col s12">
    <input
      #prodNameInput
      id="productInpt"
      type="text"
      title="Nome do produto a ser comprado"
      placeholder="Nome do produto"
      [(ngModel)]="prodName"
      (keyup.enter)="addProduct()"
      maxlength="35"
    />
    <span
      class="helper-text pink-text text-darken-4"
      data-error="wrong"
      data-success="left"
      *ngIf="prodNError != ''"
    >
      <i class="material-icons left">error_outline</i> {{ prodNError }}
    </span>
  </div>
</div>
<div class="row valign-wrapper">
  <div class="input-field col s6">
    <input
      #prodQtdInput
      type="text"
      name="prodQtd"
      title="Quantidade do produto"
      placeholder="Quantidade"
      [(ngModel)]="prodQtd"
      (keyup.enter)="addProduct()"
      (input)="hdInput($event, 8, true)"
      (change)="hdInput($event, 8, true)"
    />
    <span
      class="helper-text pink-text text-darken-4"
      data-error="wrong"
      data-success="left"
      *ngIf="prodQError != ''"
    >
      <i class="material-icons left">error_outline</i> {{ prodQError }}
    </span>
  </div>
  <div class="col s6">
    <button
      type="button"
      class="btn green darken-4"
      title="Adicionar"
      (click)="addProduct()"
    >
      <i class="material-icons left">add</i> Adicionar
    </button>
  </div>
</div>
