export class List {
  id: string;
  products: any[];
  total: number;
  dateTimeRegister: string;
  shared: boolean;
  
  private _discount: number;
  private _emporium: string;
  paymentMethod: any[];

  constructor(
    id?: string,
    products?: any[],
    total?: number,
    dateTimeRegister?: string,
    discount?: number,
    emporium?: string,
    paymentMethod?: any[],
    shared?: boolean
  ) {
    this.id = id ? id : null;
    this.products = products ? products : [];
    this.total = total ? total : 0;
    this.dateTimeRegister = dateTimeRegister ? dateTimeRegister : new Date().toUTCString();
    this.discount = discount ? discount : 0.00;
    this.emporium = emporium ? emporium : '';
    this.paymentMethod =  paymentMethod ?  paymentMethod : [];

    this.shared = shared ? shared : false;
  }

  get emporium(): string {
    return this._emporium;
  }
  set emporium(emporium: string) {
    this._emporium = emporium !== "" ? emporium.trim() : emporium;
  }

  get discount(): number {
    return this._discount;
  }
  set discount(discount: number) {
    this._discount = typeof discount === 'undefined' || discount < 0 ? 0.00 : discount;
  }

}
