import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '../product';

@Component({
  selector: 'app-prod-item',
  templateUrl: './prod-item.component.html',
  styleUrls: ['./prod-item.component.scss'],
})
export class ProdItemComponent {
  @Input() product: Product;
  @Output() delProduct: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() boughtProd = new EventEmitter();

}
