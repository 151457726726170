import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take, first } from 'rxjs/internal/operators';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

import { ListService } from '../service/list.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Product } from '../product';

import { List } from './../../model/list.model';
import { Utils } from 'src/app/lib/Utils';
import { Toast } from 'src/app/lib/toast';
import { SweetAlertService } from 'src/app/services/sweet-alert.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  private KEY_LOCAL_STORAGE = 'shopping-list';
  private LIST_URL = `${environment.domain}list-products`;

  shareData = {
    title: 'Lista de Compras',
    text: 'Estou compartilhando com vc minha lista de compras :)',
    url: this.LIST_URL
  }

  shareOptions = {
    allowed: false,
    msg: 'Você precisa fazer login e salvar sua lista antes de compartilhar!'
  }
  
  products: Product[] = [];
  purchasedProducts: Product[] = [];
  totalPurchase: number = 0.00;
  
  idList: string = '0';
  saveListParam: string;
  loadList: string = 'l'; // l = local | r = remote
  loading: boolean = false;

  //Confirmation screen
  confirmation: boolean = false;
  confirmType: string = 'success';
  confirmMsg: string = '';

  btnSave: boolean = true;
  btnFinish: boolean = true;
  actionFinish: boolean = false;

  @ViewChild("purchasesList") purchasesList: ElementRef;
  dataToExport;
  
  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private serviceList: ListService,
    private auth: AuthService,
    private libUtils: Utils,
    private libToast: Toast,
    private alert: SweetAlertService) {

  }

  async ngOnInit() {
    try {
      this.activatedRouter.paramMap.subscribe(paramMap => {
        this.idList = paramMap.get('id');
        this.saveListParam = paramMap.get('save');
        this.loadList = paramMap.get('load');
        this.allowShare();
        this.hdLoadList();
      });

      if (this.saveListParam === 's') {
        this.libUtils.loadTimeout(() => {
          this.saveList()
        });
      }
    } catch(err) {
      console.error(err);
      this.libToast.showErrorToast('Falha ao carregar lista, atualize a página!');
    }
  }

  ngAfterViewInit() {
    this.allowShare();
  }

  private allowShare() {
    this.auth.isLoggedIn().then(logged => {
      this.shareOptions.allowed = logged && this.idList != '0' ? true : false;
      this.shareData.url = `${this.LIST_URL}/${this.idList};load=r`;
    })
  }

  private hdLoadList() {
    if (this.loadList === 'r' && this.idList !== '0') {
      this.loading = true;
      this.libUtils.loadTimeout(()=> {
        this.loadRemoteList();
        this.loading = false;
      });      
    } else {
      this.loadLocalList();
    }
  }

  private loadListComponent(list: any) {
    if (list && list.products) {
      //TODO this.products = Array.isArray(list.products) ? list.products : [list.products];
      this.products = list.products ? list.products : [];
      console.log(list)
      this.totalPurchase = list.total;
    }
    if (list && list.id) {
      this.idList = list.id;
    }
    this.hdBtnFinish();
  }

  private async loadRemoteList() {
    if (!await this.auth.isLoggedIn()) {
      return this.router.navigate(['login-list', this.idList, { load: 'r' }]);
    }

    this.serviceList.getRemoteListById(this.idList).pipe(take(1)).subscribe(listRemote => {
      this.loadListComponent(listRemote);
    });
  }

  private loadLocalList() {
    let list = this.serviceList.getListLocal(this.KEY_LOCAL_STORAGE);
    this.loadListComponent(list);
  }

  private hdBtnFinish() {
    try {
      if (this.products.length < 1) {
        this.btnFinish = true;
      } else {
        this.btnFinish = false;
      }
    } catch (err) {
      console.error(err);
    }
  }

  dataGenerateToExport() {
    console.warn('PDF Generate', this.purchasesList.nativeElement);
    this.dataToExport = this.purchasesList.nativeElement;
  }

  getProductListByIndice(indice: number) {
    return this.products[indice];
  }

  boughtProd(bought: boolean, ind: number): void {
    try {
      const product = this.getProductListByIndice(ind);
      if (bought && this.products.length >= 100) {
        return this.alert.showAlert('Limite de 100 produtos excedido');
      }
      if (!bought) {
        this.totalPurchase -= product.value * product.quantity;
        this.totalPurchase = this.totalPurchase > 0 ? this.totalPurchase : 0.00;
      } else {
        this.totalPurchase += product.value * product.quantity;
      }
      this.btnSave = false;
      this.hdBtnFinish();
    } catch (err) {
      console.error(err);
      this.libToast.showErrorToast('Falha ao adicionar produto ao carrinho, atualize a página!');
    }
  }
  
  addProduct(prod: Product): void {
    try {
      if (this.products.length >= 100) {
        return alert('Limite de 100 produtos excedido');
      }
      this.products.push(_.pick(prod, ['name', 'value', 'quantity', 'bought']));
      this.btnSave = false;
    } catch(err) {
      console.error(err);
      this.libToast.showErrorToast('Falha ao adicionar novo produto, atualize a página!');
    }
  }

  delProduct(indice: number): void {
    try {
      if (indice == undefined) {
        throw new Error("undefined");
      }
      const product = this.getProductListByIndice(indice);
      this.alert.showConfirm({
        title: 'Atenção!',
        text: `Confirmar a exclusão do produto [${product.name}]`
      }).then(result => {
        if (result.isConfirmed) {
          this.products.splice(indice, 1);
          this.totalPurchase -= product.value > 0 ? product.value * product.quantity : 0;
          this.btnSave = false;
          this.hdBtnFinish();
        }
      })
    } catch(err) {
      console.error(err);
      this.libToast.showErrorToast('Falha ao Excluir produto, atualize a página!');
    }
  }

  private mountDefaultList(): List {
    return new List(
      this.idList,
      this.products,
      this.totalPurchase,
      new Date().toUTCString(),
    );
  }

  async finishShopping(list?: List) {
    try {
      if (this.products.length < 1) {
        return alert('Adicione produtos ao carrinho');
      }

      if (typeof list === 'undefined') {
        return this.actionFinish = true;
      }
      this.cancelFinish();

      const listSave = this.mountDefaultList();
      listSave.emporium = list.emporium;
      listSave.discount = list.discount;
      listSave.paymentMethod = list.paymentMethod;
      listSave.total = list.total;
      
      await this.saveList(listSave);

      this.serviceList.clearListLocal();

      this.confirmation = true;
      this.confirmMsg = 'Lista finalizada com sucesso!';
    } catch (err) {
      console.error(err);
      this.confirmType = 'error';
      this.confirmMsg = 'Falha ao finalizar lista!';
      this.libToast.showErrorToast('Falha ao finalizar lista de compras, tente novamente!');
    }
  }

  cancelFinish() {
    this.actionFinish = false;
  }

  redirectFinish() {
    try {
      this.router.navigate(['home']);
    } catch (err) {
      console.error(err);
      this.libToast.showErrorToast('Falha ao redirecionar a página inicial, atualize a página!');
    }
  }

  async saveList(listFinish?: List) {
    try {
      if (this.products.length < 1) {
        return alert('Adicione produtos para salvar');
      }

      this.loading = true;
      this.btnSave = true;
      this.idList = this.idList !== '0' ? this.idList : this.serviceList.getListId();

      const list = listFinish ? listFinish : this.mountDefaultList();

      this.serviceList.saveListLocal(
        this.KEY_LOCAL_STORAGE,
        list
      );

      let logged = await this.auth.isLoggedIn();
      if (logged) {
        const lists = await this.serviceList.getRemoteLists().pipe(first()).toPromise();
        if (lists.length >= 2 && list.id !== lists.pop().id) {
          this.loading = false;
          return alert('Por enquanto você só pode salvar 2 listas, exclua uma e tente novamente.');
        }

        const saved = await this.serviceList.saveRemoteList(list);
        this.idList = saved?.id;
        this.allowShare();
      } else {
        this.router.navigate(['login-list', this.idList, { save: 's' }]);
      }

      this.loading = false;
      this.libToast.showSuccessToast('A lista de compras foi salva :)');
    } catch (err) {
      console.error(err);
      this.loading = false;
      this.btnSave = false;
      this.libToast.showErrorToast('Falha ao Salvar lista de compras, tente novamente!');
    }
  }

}
