export class Product {

    name: string;
    value: number = 0.00;
    quantity: number;
    bought: boolean;

    constructor(
        name?: string,
        quantity?: number,
        value?: number,
        bought?: boolean
    ) {
        this.name = name ? name : null;
        this.value = value ? value : this.value;
        this.quantity = quantity ? quantity : 0;
        this.bought = bought ? bought : false;
    }

}