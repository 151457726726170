import { Component } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { AuthService } from './auth/auth.service';
import { PersonModel } from './model/person.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string = 'Lista de compras';
  person: PersonModel;
  
  constructor(private auth: AuthService) {
    const app = initializeApp(environment.firebaseConfig)
    getAnalytics(app);
    
    this.person = this.auth.person;
    this.auth.userState.subscribe(person => {
      this.person = person
    })
  }

}