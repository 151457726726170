<!-- <button type="button" class="btn amber darken-4 white-text" title="Compartilhar lista de compras" (click)="dispatch()">
    Compartilhar lista <i class="material-icons right">share</i>
</button> -->

<div class="fixed-action-btn" #fixedActionBtn>
  <a class="btn-floating btn-large orange darken-2" title="Compartilhar">
    <i class="large material-icons">share</i>
  </a>
  <ul>
    <li>
      <a class="btn-floating black" title="Mensagem" (click)="share('message')">
        <i class="material-icons">message</i>
      </a>
    </li>
    <li>
      <a class="btn-floating black" title="PDF" (click)="share('pdf')">
        <i class="material-icons">picture_as_pdf</i>
      </a>
    </li>
  </ul>
</div>
