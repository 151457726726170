<div class="home-page">
  <header class="center-align">
    <h5 class="title">Olá, que bom ver você por aqui :)</h5>
  </header>
  <div class="row highlight">
    <div class="col">
      Tenha maior flexibilidade na hora de fazer suas compras, economize tempo e dinheiro
      com suas listas na palma da mão disponíveis 24h em qualquer lugar do mundo. Use para mercado,
      farmácia, padaria, material diverso, nós te auxiliaremos em qualquer ocasião :)
    </div>
  </div>
  <div class="row">
    <div class="col s12 l4"></div>
    <div class="col s12 l4 options-highlight">
      <div class="row" *ngFor="let link of links">
        <div *ngIf="link.secure === 'all'" class="col s12">
          <a routerLink="{{link.url}}" class="btn-flat btn-large card-link">
            <i class="material-icons left">{{link.icon}}</i>
            {{link.title}}
          </a>
        </div>
        <div *ngIf="link.secure === 'public' && person.uid == null" class="col s12">
          <a routerLink="{{link.url}}" class="btn-flat btn-large card-link">
            <i class="material-icons left">{{link.icon}}</i>
            {{link.title}}
          </a>
        </div>
        <div *ngIf="link.secure === 'private' && person.uid != null" class="col s12">
          <a routerLink="{{link.url}}" class="btn-flat btn-large card-link">
            <i class="material-icons left">{{link.icon}}</i>
            {{link.title}}
          </a>
        </div>
      </div>
    </div>
    <div class="col s12 l4"></div>
  </div>
</div>