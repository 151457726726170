import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Error {

  private fireErrorCodes = [
    { code: 'auth/weak-password', msg: 'A senha deve conter pelo menos 6 caracteres' },
    { code: 'auth/invalid-email', msg: 'Tente outro e-mail, o informado não foi aceito' },
    { code: 'auth/email-already-in-use', msg: 'O e-mail informado já está cadastrado' },
    { code: 'auth/user-disabled', msg: 'Sua conta está desativada' },
    { code: 'auth/user-not-found', msg: 'Dados inválidos, você já fez seu cadastro?' },
    { code: 'auth/wrong-password', msg: 'Os dados informados estão errados' },
    { code: 'auth/internal-error', msg: 'Error na solicitação, tente novamente!' },
  ];

  private STANDARD_ERROR_MSG = ':( desculpa, houve uma falha na solicitação, tente novamente!';

  public handlerFireError(fireErro) {
    if (!fireErro || !fireErro.code) {
      return this.STANDARD_ERROR_MSG;
    }
    let msg = this.fireErrorCodes.filter(objError => objError.code === fireErro.code);
    return msg != undefined && Array.isArray(msg) ? (msg.pop()).msg : this.STANDARD_ERROR_MSG;
  }

}
