import { Component, EventEmitter, Output, Input, ElementRef, ViewChild, OnInit } from '@angular/core';
import * as M from "materialize-css/dist/js/materialize";
import { List } from 'src/app/model/list.model';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent implements OnInit {

  MSG_DISCOUNT = "O desconto não pode ser igual ou maior que o valor total.";

  @Input() totalShopping: number = 0;
  @Output() cancelFinish = new EventEmitter<boolean>();
  @Output() finishData = new EventEmitter<List>();

  emporium: string = "";
  discount: number = 0;
  paymentMethod = [];
  totalWithDiscount: number = 0;

  paymentMethodList = [
    { name: "Crédito" },
    { name: "Débito" },
    { name: "Dinheiro" },
    { name: "Vale Refeição/alimentação" },
    { name: "Outro" }
  ]
  alertMsg: string = '';

  @ViewChild('select_method') selectMethod: ElementRef;
  selectInstance = null;

  constructor() {  }

  ngOnInit() {
    this.totalWithDiscount = this.totalShopping;
  }

  ngAfterViewInit() {
    this.selectInstance = M.FormSelect.init(this.selectMethod.nativeElement, {  });
  }

  preventInput(event) {
    let value = typeof this.discount === 'undefined' || this.discount === null ? 0 : this.discount;

    if (value < this.totalShopping && value >= 0) {
      this.alertMsg = "";
      this.totalWithDiscount = this.totalShopping - value;
      return false;
    }

    event.preventDefault()
    let valueStr = value.toString().match(/\d+/g).pop();
    this.discount = parseFloat(valueStr.substring(0,2));
    this.alertMsg = this.MSG_DISCOUNT;
  }

  setPaymentMethod() {
    this.paymentMethod = this.selectInstance.getSelectedValues();
  }

  cancel() {
    this.cancelFinish.emit(false);
  }

  finishList() {
    if (this.emporium.trim() === "") {
      return this.alertMsg = "Informe o local da compra";
    }

    if (this.discount >= this.totalShopping) {  
      return this.alertMsg = this.MSG_DISCOUNT;
    }
    
    if (this.paymentMethod.length < 1) {
      return this.alertMsg = "Selecione uma forma de pagamento";
    }

    this.alertMsg = "";

    let list = new List();
    list.emporium = this.emporium;
    list.discount = this.discount;
    list.paymentMethod = this.paymentMethod;
    list.total = this.totalWithDiscount;
    
    this.finishData.emit(list);
  }

}
