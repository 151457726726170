<div *ngIf="confirmation">
  <div class="row">
    <div class="col s12 center-align">
      <i *ngIf="type === 'success'" class="large material-icons">sentiment_very_satisfied</i>
      <i *ngIf="type === 'error'" class="large material-icons">sentiment_neutral</i>
      <br/>
      <h5>{{msg}}</h5>
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col s12 l4"></div>
    <div class="col s12 l4 center-align">
      <button type="button" class="btn btn-all green darken-4" (click)="action()">
        Ir para o inicio <i class="material-icons right">done_all</i>
      </button>
    </div>
    <div class="col s12 l4"></div>
  </div>
</div>