import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Error } from '../login/error.class';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  @ViewChild('loginPass') loginPassInput: ElementRef;

  private visibleIcon = 'visibility';
  private passType = 'password';
  passObj = {
    pass: {
      type: this.passType,
      icon: this.visibleIcon,
    },
    confirm: {
      type: this.passType,
      icon: this.visibleIcon,
    },
  };

  errorMsg: string = '';
  loading: boolean = true;
  mode: string;

  frmSetNewPassword = this.fb.group({
    password: [null, [Validators.required, Validators.minLength(6)]],
    confirmPassword: [null, [Validators.required, Validators.minLength(6)]],
  });

  constructor(
    private afAuth: AngularFireAuth,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private error: Error,
  ) {}

  ngOnInit(): void {
    this.mode = this.route.snapshot.queryParams['mode'];
    this.loading = false;
  }

  ngAfterViewInit() {
    this.loginPassInput.nativeElement.focus();
  }

  get password() {
    return this.frmSetNewPassword.get('password');
  }

  get confirmPassword() {
    return this.frmSetNewPassword.get('confirmPassword');
  }

  showPassword(type: string): void {
    this.passObj[type].type = this.passObj[type].type === 'text' ? 'password' : 'text';
    this.passObj[type].icon = this.passObj[type].type === 'text' ? 'visibility_off' : 'visibility';
  }

  async submit() {
    try {
      this.loading = true;
      this.errorMsg = '';
      const password = this.frmSetNewPassword.controls['password'].value;
      const confirmPassword = this.frmSetNewPassword.controls['confirmPassword'].value;
      if (password != confirmPassword) {
        return this.errorMsg = 'Senhas não conferem';
      }
      const code = this.route.snapshot.queryParams['oobCode'];
      await this.afAuth.confirmPasswordReset(code, password);

      this.router.navigate(['login']);
    } catch (err) {
      this.errorMsg = this.error.handlerFireError(err);
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
}
