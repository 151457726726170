<div class="login-page">
  <div class="row">
    <div class="col s12 center-align">
      <h5>Acesse suas listas de compras</h5>
      <span>Fácil e rápido :)</span>
    </div>
  </div>

  <app-alert [msg]="errorMsg" [show]="errorMsg"></app-alert>
  <app-loading [loading]="loading"></app-loading>

  <div *ngIf="!loading" class="row">
    <form
      class="col s12 login-form"
      [formGroup]="loginForm"
      (ngSubmit)="login()"
    >
      <div class="row white-back">
        <div class="col s12 input-field">
          <i class="material-icons prefix">email</i>
          <input
            id="login_email"
            type="email"
            title="Email"
            formControlName="email"
            maxlength="120"
            class="validate"
          />
          <label for="login_email" class="active">Email</label>
          <span
            *ngIf="email.invalid && email.dirty && email.touched"
            class="helper-text red-text"
            >E-mail inválido</span
          >
        </div>
      </div>
      <div class="row white-back">
        <div class="col s12 input-field">
          <i class="material-icons prefix" (click)="showPassword()">{{
            passIcon
          }}</i>
          <input
            id="login_pass"
            type="{{ passType }}"
            title="Senha"
            formControlName="password"
            maxlength="20"
            autocomplete="on"
          />
          <label for="login_pass" class="active">Senha</label>
          <span
            *ngIf="password.invalid && password.dirty && password.touched"
            class="helper-text red-text"
            >Senha inválida (minimo 6 caracteres)</span
          >
        </div>
      </div>
      <div *ngIf="newUser" class="row white-back">
        <div class="col s12 input-field">
          <i class="material-icons prefix">account_circle</i>
          <input
            id="login_name"
            type="text"
            title="Nome"
            formControlName="name"
            maxlength="20"
          />
          <label for="login_name" class="active">Nome</label>
          <span
            *ngIf="name.invalid && name.dirty && name.touched"
            class="helper-text red-text"
            >Qual seu nome? (minimo 6 caracteres)</span
          >
        </div>
      </div>
      <div class="row center-align">
        <div class="col s12" *ngIf="newUser == false">
          <button
            [disabled]="!loginForm.valid"
            type="submit"
            class="btn btn-all orange accent-4 white-text"
            title="Log in"
          >
            <i class="material-icons right">lock_open</i>
            Log in
          </button>
        </div>
        <div class="col s12 margin-top-2">
          <button
            [disabled]="!loginForm.valid && name.dirty"
            (click)="register(true)"
            type="button"
            class="btn btn-all green darken-3 white-text"
            title="Cadastrar"
          >
            <i class="material-icons right">account_circle</i>
            Cadastrar
          </button>
        </div>
        <div class="col s12 margin-top-2" *ngIf="newUser == true">
          <button
            [disabled]="!loginForm.valid && name.dirty"
            (click)="register(false)"
            type="button"
            class="btn btn-all red darken-3 white-text"
            title="Cadastrar"
          >
            <i class="material-icons right">clear</i>
            Cancelar
          </button>
        </div>
        <div class="col s12 margin-top-2" *ngIf="newUser == false">
          <button
            type="button"
            class="btn btn-all indigo darken-4"
            title="Recuperar senha"
            (click)="recoverPassword()"
          >
            Esqueci minha senha
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
