<div class="row">
  <div class="col s12 center-align">
    <h5>
      <i class="material-icons">mood_bad</i>
      Não encontrado!
    </h5>
    <p>
      O recurso que você está procurando não foi encontrado, tente uma das opções
      disponíveis no menu principal. Obrigado :)
    </p>
  </div>
</div>
<div class="row  blue-grey lighten-4">
  <div class="col s12 center-align">
    <h5>
      <i class="material-icons">mood_bad</i>
      Not Found!
    </h5>
    <p>
      The resource you are trying to find is unavailable, try another one at the
      main menu. Thanks :)
    </p>
  </div>
</div>
