import { NgModule } from '@angular/core';

import { LoadingComponent } from './loading/loading.component';
import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { PanelComponent } from './panel/panel.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { SharedComponent } from './shared/shared.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BannerArticleComponent } from './banner-article/banner-article.component';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    LoadingComponent,
    AlertComponent,
    ModalComponent,
    PanelComponent,
    ConfirmationComponent,
    SharedComponent,
    NavigationComponent,
    BannerArticleComponent
  ],
  imports: [SharedModule],
  exports: [
    LoadingComponent,
    AlertComponent,
    ModalComponent,
    PanelComponent,
    ConfirmationComponent,
    SharedComponent,
    NavigationComponent,
    BannerArticleComponent
  ]
})
export class ComponentsModule { }