import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { SharedModule } from './shared.module';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ListComponent } from './product-list/list/list.component';
import { BoughtProdComponent } from './product-list/bought-prod/bought-prod.component';
import { AddProdComponent } from './product-list/add-prod/add-prod.component';
import { MylistsComponent } from './mylists/mylists.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './admin/login/login.component';
import { ForgotPasswordComponent } from './admin/forgot-password/forgot-password.component';
import { LogoutComponent } from './admin/logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AboutComponent } from './about/about.component';
import { FinishComponent } from './product-list/finish/finish.component';
import { ProdItemComponent } from './product-list/prod-item/prod-item.component';
import { ComponentsModule } from './components/components.module';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    BoughtProdComponent,
    AddProdComponent,
    MylistsComponent,
    HomepageComponent,
    LoginComponent,
    ForgotPasswordComponent,
    NotFoundComponent,
    AboutComponent,
    LogoutComponent,
    FinishComponent,
    ProdItemComponent,
  ],
  imports: [
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFirestoreModule,
    AppRoutingModule,
    ComponentsModule,
  ],
  providers: [AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }