import { Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { LinkModel, Secure } from '../model/link.model';
import { PersonModel } from '../model/person.model';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent {

  person: PersonModel;
  links: LinkModel[] = [
    { url: '/list-products/0', title: 'Criar lista', icon: 'local_grocery_store', secure: Secure.all },
    { url: '/my-lists', title: 'Minhas listas', icon: 'format_list_bulleted', secure: Secure.private },
    { url: '/login', title: 'Acessar minha conta', icon: 'lock_open', secure: Secure.public },
    // { url: '/about', title: 'Sobre', icon: 'security', secure: Secure.all },
  ]

  constructor(private auth: AuthService) {
    this.person = this.auth.person;
    this.init();
  }

  async init() {
    this.person = await this.auth.getLocalUser();
  }

}
