import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  showAlert(text) {
    Swal.fire({
      title: 'Atenção!',
      text
    });
  }

  showPersonalized({ title, text }) {
    Swal.fire({
      title,
      text
    });
  }

  showConfirm({ title, text }){
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    });
  }

}
