<button
  type="button"
  class="btn-flat delete-btn"
  (click)="delProduct.emit(product)"
  title="Remover da lista"
>
  <i class="material-icons left">cancel</i>
</button>
<div class="item-info" [title]="product.name">
  <span class="product-name">
    {{product.quantity}} x {{product.name}}
  </span>
  <span class="product-value" *ngIf="product.value">
    - {{ product.value | currency: "BRL" }} - Total {{ product.value * product.quantity | currency: "BRL" }}
  </span>
</div>
<app-bought-prod
  [(bought)]="product.bought"
  [product]="product"
  (boughtChange)="boughtProd.emit($event)"
>
</app-bought-prod>
