import { Injectable } from '@angular/core';

interface Configs {
  msg: string;
  btnOkText?: string;
  btnOkFunc?: any;
  btnCancelText?: string;
  btnCancelFunc?: string;
}

enum TypeButton {
  OK,
  CANCEL
}

interface Button {
  type: TypeButton,
  text: string;
  callback?: any;
}

@Injectable({
  providedIn: 'root'
})
export class Galert {

  private STYLE_MAIN_ALERT = 'position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255, 0.8);';
  private STYLE_BOX_ALERT = 'margin-top: 10%; margin-left: 5%; padding: 8% 0; width: 90%; height: auto; background: rgba(255,255,255, 1); text-align: center;';

  private RED = ' background: rgba(255,0,0, 1);';
  private GREEN = ' background: rgba(11,156,49, 1);';
  private STYLE_BTN = 'color: #ffffff; padding: 5px 20px; border: 1px solid rgba(0,0,0, 0.1);';
  private STYLE_BTN_OK = this.STYLE_BTN + this.GREEN;
  private STYLE_BTN_CANCEL = this.STYLE_BTN + this.RED;

  constructor() {
    console.warn('galert constructor');
  }

  private createButton(button: Button) {
      let btn = document.createElement('button');
        btn.setAttribute('type', 'button');
        btn.setAttribute('style', button.type === TypeButton.OK ? this.STYLE_BTN_OK :  this.STYLE_BTN_CANCEL);
        btn.innerHTML = button.text;
        btn.addEventListener('click', button.callback ? () => {
          button.callback(this.removeAlert);
        } : this.removeAlert);

      return btn;
  }

  createAlert(configs: Configs) {
    try {
      let mainAlert = document.createElement('div');
      mainAlert.setAttribute('id', 'galert');
      mainAlert.setAttribute('style', this.STYLE_MAIN_ALERT);

      let boxAlert = document.createElement('div');
      boxAlert.setAttribute('style', this.STYLE_BOX_ALERT);
      
      let h5 = document.createElement('h5');
          h5.innerHTML = configs.msg;

      let btnOk = this.createButton({
        type: TypeButton.OK,
        text: configs.btnOkText ? configs.btnOkText : 'OK',
        callback: configs.btnOkFunc ? configs.btnOkFunc : false
      });
     
      let btnCancel = this.createButton({
        type: TypeButton.CANCEL,
        text: configs.btnCancelText ? configs.btnCancelText : 'CANCELAR',
      });

      boxAlert.appendChild(h5);
      boxAlert.appendChild(btnOk);
      boxAlert.appendChild(btnCancel);
      mainAlert.appendChild(boxAlert);
      document.body.appendChild(mainAlert);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  removeAlert() {
    try {
      let galert = document.getElementById('galert');
      document.body.removeChild(galert);
    } catch (err) {
      console.error(err);
    }
  }

}
