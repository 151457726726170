import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as M from "materialize-css/dist/js/materialize";
import { LinkModel, Secure } from 'src/app/model/link.model';
import { PersonModel } from 'src/app/model/person.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() title = "";
  @Input() person: PersonModel;
  
  sideNav = null;
  links: LinkModel[] = [
    { url: '/home', title: 'Home', icon: 'home', secure: Secure.all },
    { url: '/login', title: 'Login', icon: 'lock_open', secure: Secure.public },
    { url: '/my-lists', title: 'Minhas Listas', icon: 'format_list_bulleted', secure: Secure.private },
    { url: '/list-products/0', title: 'Lista de Compras', icon: 'local_grocery_store', secure: Secure.all },
    { url: '/about', title: 'Sobre', icon: 'security', secure: Secure.all },
    { url: '/logout', title: 'Sair', icon: 'exit_to_app', secure: Secure.private }
  ]
  @ViewChild('mobileDemo') mobileDemo: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.sideNav = M.Sidenav.init(this.mobileDemo.nativeElement, {});
  }

  closeSidenav(): void {
    if (this.sideNav != null) {
      this.sideNav.close();
    }
  }
}