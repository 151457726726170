import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ListService } from '../product-list/service/list.service';

import { List } from '../model/list.model';
import { Utils } from '../lib/Utils';
import { Galert } from '../lib/galert';
import { Toast } from '../lib/toast';

@Component({
  selector: 'app-mylists',
  templateUrl: './mylists.component.html',
  styleUrls: ['./mylists.component.scss']
})
export class MylistsComponent implements OnInit {
  loading: boolean = false;
  lists$: Observable<List[]>;

  constructor(
    private serviceList: ListService,
    private libUtils: Utils,
    private libToast: Toast,
    private libAlert: Galert
  ) { }

  ngOnInit() {
    try {
      this.loading = true;
      this.libUtils.loadTimeout(()=> {
        this.loadLists();
        this.loading = false;
      });
    } catch (err) {
      console.error(err);
      this.libToast.showErrorToast('Falha ao carregar listas, atualize a página!');
    }
  }

  async loadLists() {
    try {
      this.lists$ = await this.serviceList.getRemoteLists();
    } catch (err) {
      console.error(err);
      this.libToast.showErrorToast('Falha ao carregar listas, atualize a página!');
    }
  }

  async deleteList(list: List) {
    try {
      this.libAlert.createAlert({
        msg: 'Confirmar Exclusão da lista?',
        btnOkText: 'SIM',
        btnOkFunc: async (close) => { 
          try {
            await this.serviceList.deleteRemoteList(list);
            this.libToast.showSuccessToast('Lista excluida!');
          } catch (err) {
            this.libToast.showErrorToast('Falha ao excluir Lista, tente novamente!');
          }

          close();
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  // goTo() {
  //   console.warn('redirecionando...');
  //   this.router.navigate(['list-products', ['0']]);
  // }

}
