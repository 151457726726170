import {
  AngularFirestore,
  AngularFirestoreCollection,
  QueryFn,
} from "@angular/fire/compat/firestore";

import { Observable } from "rxjs";

export abstract class Firestore<T extends { id: string }> {
  protected collection: AngularFirestoreCollection<T>;

  /**
   *
   * @param db the child class should pass a AngularFirestore instance
   */
  constructor(protected db: AngularFirestore) { }

  /**
   * Set collection
   * @param path path to documents list at database
   * @param queryFn function to filter document list
   */
  protected setCollection(path: string, queryFn?: QueryFn): void {
    this.collection = path ? this.db.collection(path, queryFn) : null;
  }

  /**
   * Generic function to operation set and update
   * @param item the object which will be manipulated
   * @param operation
   */
  private setItem(item: T, operation: "set" | "update"): Promise<T> {
    console.log('setItem');
    return this.collection.doc<T>(item.id)[operation](item).then(() => item);
  }

  protected getAll(): Observable<T[]> {
    return this.collection.valueChanges();
  }

  protected getById(id: string): Observable<T> {
    return this.collection.doc<T>(id).valueChanges();
  }

  /**
   * Create a new register
   * @param item
   */
  protected create(item: any): Promise<T> {
    // item.id = this.db.createId();
    return this.setItem(item, "set");
  }

  protected update(item: T): Promise<T> {
    return this.setItem(item, "update");
  }

  protected delete(item: T): Promise<void> {
    return this.collection.doc<T>(item.id).delete();
  }
}