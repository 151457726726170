import { Injectable } from '@angular/core';
import * as M from "materialize-css/dist/js/materialize";

interface ToastObj {
  html: string,
  classes?: string
}

@Injectable({
  providedIn: 'root'
})
export class Toast {

  private toastInstance(msg: string, options?: any): void{
    try {
      var toastObj: ToastObj = { html: msg };
      toastObj.classes = typeof options !== 'undefined' ? options : '';
      M.toast(toastObj);
    } catch (err) {
      console.error(err);
    }
  }

  showNormalToast(msg: string) {    
    this.toastInstance(msg);    
  }

  showInfoToast(msg: string) {
    this.toastInstance(msg, 'blue darken-4');
  }

  showSuccessToast(msg: string) {
    this.toastInstance(msg, 'green darken-4');
  }

  showErrorToast(msg: string) {
    this.toastInstance(msg, 'red darken-4');
  }

  dismissAllToast() {
    try {
      M.toast.dismissAll();
    } catch (err) {
      console.error(err);
    }
  }

}
