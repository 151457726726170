import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @Input() msg: string = '';
  /**
   * @param string [error, warning, sucess, msg]
   */
  @Input() type: string = 'error';
  @Input() show: boolean = false;
  constructor() { }
}
