import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as M from "materialize-css/dist/js/materialize";
import { Toast } from 'src/app/lib/toast';

interface SharedData {
  title: string;
  text: string;
  url: string;
}

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent {

  @Input() sharedData: SharedData;
  @Input() options = {
    allowed: true,
    msg: ''
  }
  @Input() dataGenerate: any;
  @Output() hdDataGenerate = new EventEmitter<any>();

  @ViewChild('fixedActionBtn') fixedActionBtn: ElementRef;

  constructor(
    private libToast: Toast
  ) { }

  ngOnChanges() {
    console.warn('Change shared component: ', this.dataGenerate, this.sharedData);
  }

  ngAfterViewInit() {
    M.FloatingActionButton.init(this.fixedActionBtn.nativeElement, {
      direction: 'left',
    });
  }

  share(action = "") {
    switch(action) {
      case 'pdf':
        this.hdDataGenerate.emit();
        break;
      case 'message':
        this.dispatch();
        break;
      default:
        console.error('Invalid method');
    }

  }

  private dispatch() {
    if (this.options.allowed) {
      this.shareList();
    } else {
      this.libToast.showErrorToast(this.options.msg);
    }
  }

  private async shareList() {
    try {
      const navigator = window.navigator as any;
      if (navigator.share) {
        await navigator.share(this.sharedData);
      } else {
        this.libToast.showErrorToast('Compartilhamento Indisponível');
      }
    } catch (err) {
      console.error(err);
      this.libToast.showErrorToast('Falha no compartilhamento :/ atualize a página e tente novamente!');
    }
  }

}
