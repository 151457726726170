import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
// import { auth } from 'firebase/compat/app';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { User } from 'firebase/compat';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';

import { Storage } from '../lib/storage';
import { PersonModel } from '../model/person.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  afAuthLocal;
  user: any;

  person: PersonModel = new PersonModel();
  userState: Observable<PersonModel>;
  userObserver = new EventEmitter();

  private KEY_STORAGE = "user";

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    private storage: Storage
  ) {
    // Create a personalized person object
    this.userState = new Observable(observer => {
      this.userObserver.subscribe(user => {
        this.person.uid = user && user.uid ? user.uid : null
        this.person.displayName = user && user.displayName ? user.displayName : 'Visitante';
        observer.next(this.person);
      })
    });

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        this.userObserver.emit(user);
        this.storage.setItem(this.KEY_STORAGE, this.user);
      } else {
        this.userObserver.emit(null);
        this.storage.removeItem(this.KEY_STORAGE);
      }
    })
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      let user = this.storage.getItem(this.KEY_STORAGE);
      if (user !== null) {
        return true;
      }

      this.afAuthLocal = _.cloneDeep(this.afAuth);
      user = await this.afAuthLocal.authState.pipe(first()).toPromise();

      if (user && user.uid) {
        return true;
      }

      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getLocalUser() {
    try {
      let person = new PersonModel();

      this.afAuthLocal = _.cloneDeep(this.afAuth);
      this.user = await this.afAuthLocal.authState.pipe(first()).toPromise();

      person.uid = this.user && this.user.uid ? this.user.uid : null;
      person.displayName = this.user && this.user.displayName ? this.user.displayName : 'Visitante';

      return person;
    } catch (err) {
      console.error(err);
    }
  }

  async loginWithGoogle() {
    await this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    this.router.navigate(['list-products']);
  }

  async login(email: string, password: string) {
    return await this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async register(email: string, password: string) {
    return await this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  async updateUserProfile(uname: string, uphoto: string) {
    await this.afAuth.authState.subscribe(user => {
      if (user) {
        user.updateProfile({
          displayName: uname,
          photoURL: uphoto
        }).then(() => {
          this.storage.setItem(this.KEY_STORAGE, this.user);
        }, (error) => {
          throw error
        });
      }
    });
  }

  sendPasswordResetEmail(passwordResetEmail: string) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  async logout() {
    try {
      await this.afAuth.signOut();
      this.user = null;
      this.userObserver.emit(null);

      this.storage.clearStorage();
    } catch (err) {
      console.error(err);
    }
  }

}