import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class Storage {

  private exception(key: string): void {
    if (!key) {
      throw new Error('Keyword to storage is required');
    }
  }

  setItem(key: string, value: any): boolean {
    try {
      this.exception(key);

      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  getItem(key: string) {
    try {
      this.exception(key);

      let value = localStorage.getItem(key);

      return value ? JSON.parse(value) : null;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  removeItem(key: string): boolean {
    try {
      this.exception(key);

      localStorage.removeItem(key);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  clearStorage(): boolean {
    try {
      localStorage.clear();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

}