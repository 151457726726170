<div class="container">
  <div class="row">
    <div class="col s12">
      <h5>Minhas Listas</h5>
    </div>
  </div>
</div>

<div class="row">
  <div class="col s12 l2"></div>
  <div class="col s12 l8" *ngIf="(lists$ | async) as lists">
      <div class="row border-bottom" *ngFor="let list of lists$ | async">
          <div class="col s2">
            <button type="button" class="btn-small red darken-2" (click)="deleteList(list)" title="Excluir lista">
              <i class="large material-icons">delete_forever</i>
            </button>
          </div>
          <div class="col s10">
            <a [routerLink]="['/list-products/', list.id, { load: 'r' }]" title="Clique para reabrir a lista">
              <i class="material-icons right">autorenew</i>
              <span class="blue-grey-text text-darken-4 text-bould">
                Lista de {{list.dateTimeRegister | date:'dd/MM/yyyy'}} |
                <span [ngClass]="{'text-bould': true,
                                  'blue-text': list.emporium === '',
                                  'red-text': list.emporium !== ''}">({{list.emporium === '' ? 'Aberta' : 'Finalizada'}})</span>
              </span>
              <br/>
              <span class="black-text">
                <b>Local:</b> {{list.emporium === '' ? 'A finalizar' : list.emporium}} <br/>
                <b>Total {{list.emporium === '' ? 'parcial' : ''}}:</b> {{list.total | currency: 'BRL'}} | 
                <b>Desconto:</b> {{list.discount | currency: 'BRL'}}
              </span>
            </a>
          </div>
      </div>
  </div>
  <div class="col s12 l2"></div>
</div>

<app-loading [loading]="loading"></app-loading>

<div class="container">
  <div *ngIf="(lists$ | async) as lists">
    <div class="row" *ngIf="lists.length > 0">
      <div class="col s12">
        Por enquanto você só poderá salvar 2 listas :/
      </div>
    </div>
    <div class="row" *ngIf="lists.length < 1">
      <div class="col s12">
        Salve pelo menos uma lista de compras :)
      </div>
    </div>
  </div>
</div>