import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  @Input() confirmation: boolean = false;
  @Input() type: string = "success"; // success | error
  @Input() msg: string = "Sucesso :)";
  @Output() btnAction = new EventEmitter<boolean>();

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.type = this.type === "" ? 'error' : this.type;
    this.msg = this.msg === "" ? 'Sucesso :)': this.msg;
  }

  action() {
    try {
      this.btnAction.emit(true);
    } catch (err) {
      console.error(err);
    }
  }

}