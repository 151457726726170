<nav class="amber darken-4 white-text no-margin">
  <div class="nav-wrapper">
    <a routerLink="/home" class="brand-logo">
      <i class="material-icons left">local_grocery_store</i>
      {{title}}
    </a>
    <a href="#" data-target="mobile-demo" class="sidenav-trigger">
      <i class="material-icons">menu</i>
    </a>
    <ul class="right hide-on-med-and-down">
      <li *ngFor="let link of links">
        <a *ngIf="link.secure === 'all'" (click)="closeSidenav()" routerLink="{{link.url}}" routerLinkActive="active">
          <i class="material-icons left">{{link.icon}}</i> {{link.title}}
        </a>
        <a *ngIf="link.secure === 'public' && person.uid == null" (click)="closeSidenav()" routerLink="{{link.url}}" routerLinkActive="active">
          <i class="material-icons left">{{link.icon}}</i> {{link.title}}
        </a>
        <a *ngIf="link.secure === 'private' && person.uid != null" (click)="closeSidenav()" routerLink="{{link.url}}" routerLinkActive="active">
          <i class="material-icons left">{{link.icon}}</i> {{link.title}}
        </a>
      </li>
    </ul>
  </div>
</nav>
<ul class="sidenav" id="mobile-demo" #mobileDemo>
  <li><div class="user-view">
    <img class="circle" src="../assets/images/person.png">
    <span>Olá <b>{{person.displayName}}</b> seja bem vindo(a)!</span>
  </div></li>
  <li *ngFor="let link of links">
    <a *ngIf="link.secure === 'all'" (click)="closeSidenav()" routerLink="{{link.url}}" routerLinkActive="active">
      <i class="material-icons left">{{link.icon}}</i> {{link.title}}
    </a>
    <a *ngIf="link.secure === 'public' && person.uid == null" (click)="closeSidenav()" routerLink="{{link.url}}" routerLinkActive="active">
      <i class="material-icons left">{{link.icon}}</i> {{link.title}}
    </a>
    <a *ngIf="link.secure === 'private' && person.uid != null" (click)="closeSidenav()" routerLink="{{link.url}}" routerLinkActive="active">
      <i class="material-icons left">{{link.icon}}</i> {{link.title}}
    </a>
  </li>
</ul>