import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { AuthService } from 'src/app/auth/auth.service';
import { Firestore } from 'src/app/lib/firestore';
import { Storage } from 'src/app/lib/storage';

import { List } from './../../model/list.model';

@Injectable({
  providedIn: 'root'
})
export class ListService extends Firestore<List> {

  private idList: string = null;

  constructor(
    private auth: AuthService,
    private firestore: AngularFirestore,
    private storage: Storage
  ) {
    super(firestore);
    this.init();
  }

  private init(): void {
    // Get the user id
    this.auth.afAuth.authState.subscribe((user) => {
      if (user) {
        // collection/usercollection/document/...
        this.setCollection(
          `user/${user.uid}/lists`,
          (ref) => ref.orderBy("dateTimeRegister", "desc")
        );
        return;
      }
      // When user log out
      this.setCollection(null);
    });
  }

  getListId(): string {
    if (this.idList != null) {
      return this.idList;
    }

    this.idList = this.firestore.createId();
    return this.idList
  }

  saveListLocal(key: string, list: any) {
    try {
      this.storage.setItem(key, JSON.stringify(list));
    } catch (err) {
      console.error(err);
    }
  }

  getListLocal(key: string): any {
    try {
      let list = this.storage.getItem(key);
      return list ? JSON.parse(list) : '';
    } catch (err) {
      console.error(err);
    }
  }

  clearListLocal(): void {
    try {
      this.storage.clearStorage();
    } catch (err) {
      console.error(err);
    }
  }

  getRemoteLists() {
    try {
      return this.getAll();
    } catch (err) {
      console.error(err);
    }
  }

  getRemoteListById(idList: string) {
    try {
      return this.getById(idList);
    } catch (err) {
      console.error(err);
    }
  }

  async deleteRemoteList(list: List) {
    try {
      this.delete(list);
    } catch (err) {
      console.error(err);
    }
  }

  saveRemoteList(list: List) {
    try {
      const { id, products, total, dateTimeRegister, discount, emporium, paymentMethod, shared } = list;
      return this.create({
        id,
        products,
        total,
        dateTimeRegister,
        discount,
        emporium,
        paymentMethod,
        shared
      });
    } catch (err) {
      console.error(err);
    }
  }

}