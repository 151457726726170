import { Component, ViewChild, ElementRef } from '@angular/core';
import * as M from "materialize-css/dist/js/materialize";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @ViewChild('modal_comp') modalComp: ElementRef;
  instanceModal: any;

  constructor() { }

  ngAfterViewInit() {
    this.instanceModal = M.Modal.init(this.modalComp.nativeElement, {
      startingTop: '1%',
      endingTop: '1%'
    });
  }

  openModal() {
    this.instanceModal.open();
  }

  closeModal() {
    this.instanceModal.close();
  }

}
