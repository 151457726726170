import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Utils {

  loadTimeout(func, time = 1000){
    setTimeout(()=> {
      func();
    }, time);
  }

}
